<template>
  <v-dialog v-model="overpaidDialog[tab]" max-width="840" width="840">
    <v-card class="pa-3">
      <!--Header-->
      <v-card-title>
        <h3>Customer Overpayment</h3>
        <v-spacer />
        <v-btn small right icon @click="$emit('closeOverpaidDialog', false)"
          ><v-icon small>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <!--Form-->
      <v-form ref="form">
        <v-container>
          <!--Alert Message-->
          <v-row>
            <v-col>
              <v-alert text type="info">
                Use this form to allocate the remaining amount as a "Customer
                Overpayment"
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="8">
              <v-text-field
                :placeholder="String(amountLeftToAllocate)"
                v-model="overpaidAllocated"
                readonly
                outlined
                dense
                label="Overpaid Amount"
              ></v-text-field>
            </v-col>
            <!--Button-->
            <v-col md="4">
              <v-btn color="primary" @click="allocateOverpayment()"
                >Allocate As Overpayment</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "OverpaidModal",
  props: ["overpaidDialog", "item", "amountLeftToAllocate", "tab"],
  data() {
    return {
      overpaidAllocated: null,
    };
  },
  //
  watch: {
    amountLeftToAllocate(newValue, oldValue) {
      console.log(newValue, oldValue);
      this.overpaidAllocated = newValue;
    },
  },
  methods: {
    allocateOverpayment() {
      this.$emit("passOverpaid", this.overpaidAllocated);
      this.$emit("closeOverpaidDialog", false);
    },
  },
};
</script>